import { useCallback, useEffect, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useRelayEnvironment } from 'react-relay';
import { getBuyerId, getGuestId, getUserType, getPriceBookName } from 'dibs-cookie-jar';
import { updateRelayVariables } from '../actions/sharedRelayVariableActions';
import {
    CUSTOMER_TYPE_TRADE,
    CUSTOMER_TYPE_VIP,
} from 'dibs-buyer-layout/exports/userStorageConstants';
import {
    getUserZipCode,
    getUserSessionCountryCode,
    getUserSessionRegions,
    getRegionsList,
} from 'dibs-regional-info/exports/regionalInfoHelpers';
import {
    setUserRegionalInfo,
    forceSetUserRegionalInfo,
} from 'dibs-regional-info/exports/setUserRegionalInfo';
import serverVars from 'server-vars';
import { getPersonalizedRerankAbTestVariant } from '../utils/abTestHelper';
import { useSbSelector } from '../reducers/useSbSelector';

export type UserRelayVars = {
    userId: string;
    personalizationId?: string;
    guestId?: string;
    rerankUserId?: string;
    rerankGuestId?: string;
    fetchUser: boolean;
    userIds: string[];
    isTrade: boolean;
    isVip: boolean;
    userZipCode: string;
    userCountryCode: string;
    priceBookName?: string;
    fetchRegionalInfo: boolean;
    regionsList?: string[];
    fetchSb?: boolean;
};

export type UserRefetchFunc = (
    vars: UserRelayVars & { isClient: boolean },
    renderVariables: (UserRelayVars & { isClient: boolean }) | null,
    callback: (err: Error, props: Record<string, unknown>) => void
) => void;

type Props = {
    onUserRefetch: UserRefetchFunc;
    shouldUpdateRelayVariables?: boolean;
    refreshPageOnTradeLogin?: boolean;
};

export const useSharedUserAndRelayVarsLoader = ({
    onUserRefetch,
    shouldUpdateRelayVariables = true,
    refreshPageOnTradeLogin = true,
}: Props): void => {
    const environment = useRelayEnvironment();
    const dispatch = useDispatch();
    const {
        fetchUser,
        updateAuctionItem,
        updateItemPricing,
        updatedUserCountryCode,
        personalizationId,
    } = useSbSelector(state => {
        /**
         * updateUserState is used for responsive. The authentication is on the navigation is
         * managed in dbl, so we need to know when a user has successfully logged in/registered
         *
         * fetchUser is used for mobile. The mobile nav works a little bit differently from dbl.
         * It calls a callback when a user clicks an auth button and we handle all of the auth
         * flow in `app-buyer-finding`.
         *
         * TODO: Make sure they work the same way.
         * NOTE: updateUserState does not seem to exist, even for responsive it is using fetchUser
         */
        const relayVars = state.relayVariables.variables;
        return {
            personalizationId: relayVars.personalizationId || '',
            fetchUser: state.header.updateUserState || relayVars.fetchUser,
            updateAuctionItem: !!relayVars.updateAuctionItem,
            updateItemPricing: !!relayVars.updateItemPricing,
            updatedUserCountryCode: relayVars.updatedUserCountryCode || '',
        };
    });

    const [userFetched, setUserFetched] = useState(false);

    const triggerRefetch = useCallback(async (): Promise<void> => {
        const getUserRelayVars = async (): Promise<UserRelayVars> => {
            /**
             * Must default to an empty string! Because the default value for userId in the
             * relay query is set to an empty string. Defaulting to anything different will ruin
             * your day, trust me ;)
             */
            const userId = getBuyerId(document.cookie) || '';
            const guestId = getGuestId(document.cookie) || '';
            const userType = getUserType(document.cookie);
            const hasUserId = !!userId;
            const userCountryCode = updatedUserCountryCode || getUserSessionCountryCode();
            const hasUserLoggedIn = hasUserId && !serverVars.get('userType');

            /**
             * check and set (if missing) user regional info in sessionStorage
             * this is needed when user opens a new tab, and sessionStorage is still empty
             */
            // await is needed here! It's needed to make sure to wait till
            // sessionStorage.userGeoInfo has enough data to continue
            // this function execution where userZipCode, userCountryCode and hasSessionRegions
            // is being set a few lines down
            if (environment) {
                // force regional info reset just for newly logged in users
                if (hasUserLoggedIn) {
                    await forceSetUserRegionalInfo({
                        relayEnv: environment,
                        cookieDomain: serverVars.get('cookieDomain'),
                        userId,
                    });
                } else {
                    await setUserRegionalInfo({
                        relayEnv: environment,
                        cookieDomain: serverVars.get('cookieDomain'),
                        userId,
                    });
                }
            }

            const personalizedRerankAbTestVariant = getPersonalizedRerankAbTestVariant();
            const isPersonalizedRerankAbTestVariantOrControl = !!personalizedRerankAbTestVariant;
            const isPersonalizedRerankAbTestVariant = personalizedRerankAbTestVariant === 'variant';

            // For logged in user personalization was already applied on the server
            const isPersonalizationAppliedOnServer =
                isPersonalizedRerankAbTestVariant &&
                !!personalizationId &&
                personalizationId === userId;

            return {
                userId,
                fetchUser: hasUserId,
                userIds: hasUserId ? [userId] : [],
                isTrade: userType === CUSTOMER_TYPE_TRADE,
                isVip: userType === CUSTOMER_TYPE_VIP,
                userZipCode: getUserZipCode(),
                userCountryCode,
                fetchRegionalInfo: !getUserSessionRegions(),
                priceBookName: getPriceBookName(document.cookie) || 'DEFAULT',
                fetchSbOnUserChange: false,
                ...(isPersonalizedRerankAbTestVariant
                    ? {
                          fetchSbOnUserChange: hasUserId && !isPersonalizationAppliedOnServer, // Needs to trigger s&b refetch on user login - personalized rerank may be applied
                          personalizationId: userId,
                          guestId,
                          regionsList: getRegionsList(),
                      }
                    : {}),
                ...(isPersonalizedRerankAbTestVariantOrControl
                    ? {
                          rerankUserId: userId,
                          rerankGuestId: guestId,
                      }
                    : {}),
            };
        };

        const variables = {
            ...(await getUserRelayVars()),
            afterDisplay: true,
            afterDisplayOrBuaSsr: true,
            isClient: true, // Do not use 'isClient' anymore, we are migrating to 'afterDisplay'.
        };

        onUserRefetch(variables, null, (err, additionalVariables) => {
            /**
             * Mimic relay behavior - only change the variables after a successful refetch.
             */
            if (err) {
                setUserFetched(false);
            } else if (shouldUpdateRelayVariables) {
                dispatch(
                    updateRelayVariables({
                        ...variables,
                        ...additionalVariables,
                        updateAuctionItem: false,
                        updateItemPricing: false,
                    })
                );
            }
        });

        setUserFetched(variables.fetchUser);
    }, [
        onUserRefetch,
        updatedUserCountryCode,
        environment,
        personalizationId,
        shouldUpdateRelayVariables,
        dispatch,
    ]);
    /**
     * Fetch the user if needed and refetch any fields that depend on `isClient`
     */
    useEffect(() => {
        triggerRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        /**
         * First condition: allow for page refreshes from auction pdps
         * Second condition: For cases where the user logs in while browsing the page.
         */
        if (updateAuctionItem) {
            triggerRefetch();
        } else if (fetchUser && !userFetched) {
            const userType = getUserType(document.cookie);
            const isTrade = userType === CUSTOMER_TYPE_TRADE;
            // FINDING-13500: Need to refresh when logging-in as trade user so that
            // "ut" param is appended to url. Without "?ut=trade" in url, some of the
            // trade-specific features, such as filtering by net price, do not work
            if (isTrade && refreshPageOnTradeLogin) {
                window.location.reload();
            } else {
                triggerRefetch();
            }
        } else if (updateItemPricing) {
            triggerRefetch();
        }
    }, [
        fetchUser,
        refreshPageOnTradeLogin,
        triggerRefetch,
        updateAuctionItem,
        updateItemPricing,
        userFetched,
    ]);

    return;
};

// Wrapper for class components
export const SharedUserAndRelayVarLoader: FC<Props> = props => {
    useSharedUserAndRelayVarsLoader(props);
    return null;
};
